<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Terms Condition</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Terms Condition</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Terms ConditionArea -->
<div class="privacy-policy ptb-100">
    <div class="container">
        <div class="privacy-policy-text">
            <h2>INTRODUCTION</h2>
            <p>
                In using the ELFS.com service, you are deemed to have accepted
                the Terms and Conditions of the agreement listed below or as may
                be revised from time to time, which is, for an indefinite period
                and you understand and agree that you are bound by such terms
                till the time you access this website. We reserve the right to
                change these terms & conditions from time to time without any
                obligation to inform you and it is your responsibility to look
                through them as often as possible.
            </p>
            <h2>OWNERSHIP OF RIGHTS</h2>
            <p>
                Any use of this website or its contents, including copying or
                storing it or them in whole or part, other than for your own
                personal, non-commercial use is prohibited without the explicit
                permission of ELFS. All information displayed, transmitted
                or carried on ELFS is protected by copyright and other
                intellectual property laws. All rights, including copyright, in
                this website are owned by or licensed to ELFS. This site is
                designed, updated and maintained independently by ELFS. The
                content is owned by ELFS. You may not modify, publish,
                transmit, transfer, sell, reproduce, create derivative work
                from, distribute, repost, perform, display or in any way
                commercially exploit any of the content available in the
                website.
            </p>
            <h2>DISCLAIMER</h2>
            <p>
                Information, services, names, pictures, advertisements, images
                and contents are provided "as is" on this web site. ELFS
                Private Limited expressly disclaims all and any kind of
                warranties, whether express or implied, including, but not
                limited to, implied warranties of merchantability and fitness
                for a particular purpose. Parties using the information provided
                on this web site may do so after satisfying themselves that the
                same suits their individual requirements. ELFS uses
                information voluntarily provided by users to optimise their
                experience on our web site, whether to provide personalised
                elements on the site, or to prepare a better future content base
                in the interests of our users. However, the information
                contained on this web site is not intended to be, and should not
                be treated as legal or professional advice. "ELFS" is a
                registered trademark. All other trademarks, company names or
                logos, and product names referred to in this web site remain the
                property of their respective owners. In no event shall ELFS
                be responsible to any person or entity for any loss or damage,
                whether direct, indirect, incidental, consequential or
                otherwise, arising out of access or use or dissemination of
                information contained on this web site, including, but not
                limited to, loss of profits, data, or damage to the user`s
                computer systems. The information, contents, names, images, etc.
                may be changed, updated, modified and/or improved from time to
                time without any notice. ELFS shall make all reasonable
                attempts to eliminate and exclude viruses from this web site.
                However, ELFS does not ensure or accept any liability in
                respect thereof. Parties using this web site may take suitable
                precautions before downloading information. This Disclaimer
                Clause and any claims arising out of the use of the information
                from this web site shall be governed by the laws of India and
                only the Courts in Delhi, and no other Courts, shall have
                jurisdiction over the same. By accessing this web site, the user
                accepts this.
            </p>
            <h2>COMMUNICATION</h2>
            <p>
                When you visit the Website or send emails to us, you are
                communicating with us electronically. You consent to receive
                communications from us electronically. We will communicate with
                you by email or by posting notices on the Website. You agree
                that all agreements, notices, disclosures and other
                communications that we provide to you electronically satisfy any
                legal requirement that such communications be in writing.
            </p>
            <h2>WEBSITE FEEDBACK, USER COMMENTS AND USER GENERATED CONTENT</h2>
            <p>
                All reviews, comments, feedback, postcards, suggestions, ideas,
                and other submissions disclosed, submitted or offered to
                ELFS.com on or by this Website or otherwise disclosed,
                submitted or offered in connection with your use of this Website
                (collectively, the "Comments") shall be and remain ELFS.com
                property. Such disclosure, submission or offer of any Comments
                shall constitute an assignment to ELFS.com of all worldwide
                rights, titles and interests in all copyrights and other
                intellectual properties in the Comments. Thus, we own
                exclusively all such rights, titles and interests and shall not
                be limited in any way in its use, commercial or otherwise, of
                any Comments. We will be entitled to use, reproduce, disclose,
                modify, adapt, create derivative works from, publish, display
                and distribute any Comments you submit for any purpose
                whatsoever, without restriction and without compensating you in
                any way. ELFS.com is and shall be under no obligation (1)
                to maintain any Comments in confidence; (2) to pay you any
                compensation for any Comments; or (3) to respond to any
                Comments. You agree that any Comments submitted by you to the
                Website will not violate this policy or any right of any third
                party, including copyright, trademark, privacy or other personal
                or proprietary right(s), and will not cause injury to any person
                or entity. You further agree that no Comments submitted by you
                to the Website will be or contain libelous or otherwise
                unlawful, threatening, abusive or obscene material, or contain
                software viruses, political campaigning, commercial
                solicitation, chain letters, mass mailings or any form of
                'spam'. ELFS.com does not regularly review posted Comments,
                but does reserve the right (but not the obligation) to monitor
                and edit or remove any Comments submitted to the Website. You
                grant ELFS.com the right to use the name that you submit in
                connection with any Comments. You agree not to use a false email
                address, impersonate any person or entity, or otherwise mislead
                as to the origin of any Comments you submit. You are and shall
                remain solely responsible for the content of any Comments you
                make and you agree to indemnify ELFS.com and its affiliates
                for all claims resulting from any Comments you submit.
                ELFS.com and its affiliates take no responsibility and
                assume no liability for any Comments submitted by you or any
            </p>
        </div>
    </div>
</div>
<!-- End Terms Condition Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Enter your email"
                            name="EMAIL"
                            required=""
                            autocomplete="off"
                        />
                        <button type="submit" class="btn btn-primary">
                            Subscribe
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
