import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    domainName: string = 'https://exlinefreights.com/';
    companyDetails = {
        name: 'Express Line Freight Services Wll',
        addresses: [
            {
                crNo: '149961',
                building: 'Al Kazim Tower',
                doorNo: 'Opposite of Crowne Plaza Building#43',
                floor: '2nd floor, office #17',
                city: 'Doha',
                country: 'Qatar',
                simpleAddress: `CR NO : 149961, Al Kazim Tower,<br/> Opposite of Crowne Plaza Building#43,<br/>2nd floor, office #17,<br/>Doha Qatar`,
            },
        ],
        socialMedia: {
            facebook:
                'https://www.facebook.com/Expressline-Freight-Services-WLL-100673758695021/',
            instagram:
                'https://www.instagram.com/express_line_freight_services/',
        },
    };

    constructor() {}

    getSimpleAddress = () => {
        console.log({ this: this, companyDetails: this.companyDetails });
        return this.companyDetails.addresses[0].simpleAddress;
    };

    getSocialMediaLinks = () => {
        return this.companyDetails.socialMedia;
    };
}
