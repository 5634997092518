<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safer, Faster and Reliable Logistics Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/industrial-port-container.jpg" alt="image">
                    <!-- <a href='https://www.freepik.com/photos/business'>Business photo created by onlyyouqj - www.freepik.com</a> -->
                    <div class="service-caption">
                        <h3>Road Freight</h3>
                        <p>Deliver your products as quickly as 1, 2, or 3-5 business days after their ship date. Orders
                            are delivered every day of the week.</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/aerial-view-container-cargo-ship-sea.jpg" alt="image">
                    <!-- <a href='https://www.freepik.com/photos/water'>Water photo created by tawatchai07 - www.freepik.com</a> -->
                    <div class="service-caption">
                        <h3>Ocean Freight</h3>
                        <p>Keep costs low with our simple, predictable pricing. Just one fee for pick, pack, and ship,
                            and no peak surcharges. We offer up to 60% discounts on multi-unit orders..</p>
                        <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/tsa-known-shipper-program.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Air Freight</h3>
                        <p>Automate your fulfillment through pre-built applications or directly through our APIs.
                            Streamline your inventory management with pooled inventory.</p> <a
                            routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/customsbroker.jpg" alt="image">
                    <div class="service-caption">
                        <h3>B2B Exchange</h3>
                        <p>Get products closer to customers with automatic inventory placement, and scale to meet
                            seasonal spikes without increasing your fixed costs.</p> <a routerLink="/services-details"
                            class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/wallmart-delivery.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Home Shipping</h3>
                        <p>Improve sales conversion by sharing Amazon-backed delivery dates with customers based on
                            their location and order items.</p> <a routerLink="/services-details"
                            class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/pre-move-game-plan.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Office Moving</h3>
                        <p>Easily share shipment confirmation and tracking details for any carrier with MCF's tracking
                            website.</p> <a routerLink="/services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/services"><span
                                aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/services">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services"><span
                                aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- Our End Services Area -->

<!-- Best Logistic Area -->
<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2>Best <span>Logistic Service</span> Solution Near you</h2>
                    <p>At vero eos et et iusto odio ducimus qui blanditiis praesentium animi voluptatum deleniti atque
                        corrupti dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                        sunt culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                </div>
                <a routerLink="/about" class="default-btn-one">Read More</a>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="assets/img/services/logistic-services.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Best Logistic Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->