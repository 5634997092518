import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  partners = [{
    name: "Abu Issa Holding",
    img: "aih-logo.png"
  }, {
    name: "Al-Dar Al-Jameel",
    img: "al-dar-logo.png"
  }, {
    name: "Al-Fardan Group",
    img: "al-fardan-logo.png"
  }, {
    name: "Barer Holding",
    img: "barer-logo.png"
  }, {
    name: "Black Arrow",
    img: "black-arrow-logo.png"
  }, {
    name: "CECC",
    img: "cecc-logo.png"
  }, {
    name: "Crystal",
    img: "crystal-logo.png"
  }, {
    name: "HBK",
    img: "hbk-logo.png"
  }, {
    name: "Integrated Smart Solutions",
    img: "iss-logo.png"
  }, {
    name: "Maharlika Cargo",
    img: "maharlika-logo.png"
  }, {
    name: "ManTech Office Systems",
    img: "mantech-logo.png"
  }, {
    name: "MixMax",
    img: "mixmax-logo.png"
  }, {
    name: "Qatar Foundation",
    img: "qatar-foundation-logo.png"
  }, {
    name: "Qatar University",
    img: "qatar-univer-logo.png"
  }, {
    name: "Sketch Advertising W.L.L",
    img: "sketch-logo.png"
  }, {
    name: "Spring International",
    img: "spring-int-logo.png"
  }, {
    name: "AlWated Trading and Contracting Co Ltd",
    img: "wated-logo.png"
  }]
  constructor() { }

  ngOnInit(): void {
  }

}
