import { Component } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
    domainName = '';
    emails = [
        'ibrahim@exlinefreights.com',
        'sheena@exlinefrieghts.com',
        'sales@exlinefrieghts.com',
    ];
    address = '';

    constructor(private service: ConfigService) {
        this.domainName = this.service.domainName;
        this.address = this.service.getSimpleAddress();
    }
}
