<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- About Safe Area -->
<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-safe-text">
                    <h2>what are we driven by?</h2>
                    <p>
                        We are the fastest growing express logistics service
                        provider in Qatar catering to end-to-end supply chain
                        solutions. This, for us, is not just a corporate brand
                        building cliché but a fact. The one we are very proud
                        of. Since our inception, we have seen a near 100% growth
                        in revenue on a year-to-year basis.
                    </p>
                </div>

                <div class="shipping-card">
                    <div class="shipping-contant">
                        <div class="shipping-sign">
                            <img src="assets/img/sign.png" alt="image" />
                        </div>

                        <div class="shipping-image">
                            <img src="assets/img/clients/ceo.jpg" alt="image" />
                        </div>

                        <h3>MOHD ALI S M AL MUHANNADI</h3>
                        <span>CEO, Express Line Freight Service</span>
                        <p>
                            Nor again is there anyone who loves or pursues or
                            desires to.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="safe-image">
                    <img src="assets/img/speciality/businessman-checking-time-hand-watch.jpg" alt="image" />
                    <!-- <a href='https://www.freepik.com/photos/circle'>Circle photo created by rawpixel.com - www.freepik.com</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Safe Area -->

<!-- About Info -->
<div class="about-info-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Mission</h3>

                    <ul>
                        <li>
                            <i class="bx bx-check"></i> Proficiency in fastest
                            shipment
                        </li>
                        <li><i class="bx bx-check"></i> Reduce complexities</li>
                        <li>
                            <i class="bx bx-check"></i> Inexpensive cargo
                            transport
                        </li>
                        <li>
                            <i class="bx bx-check"></i> Outlet in all over the
                            world
                        </li>
                        <li>
                            <i class="bx bx-check"></i> Profound business plans
                        </li>
                        <li>
                            <i class="bx bx-check"></i> Best ever customer
                            satifaction
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Promises</h3>

                    <ul>
                        <li>
                            <i class="bx bx-check"></i> To provide best logistic
                            service worldwide.
                        </li>
                        <li>
                            <i class="bx bx-check"></i> Guaranteed secure
                            shipment
                        </li>
                        <li>
                            <i class="bx bx-check"></i> No violation in
                            government regulation
                        </li>
                        <li>
                            <i class="bx bx-check"></i> Sustainability in our
                            service
                        </li>
                        <li><i class="bx bx-check"></i> Tranparency</li>
                        <li>
                            <i class="bx bx-check"></i> Crossover in agreements
                            are prohibited
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="about-info-card">
                    <h3>Our Vission</h3>

                    <ul>
                        <li>
                            <i class="bx bx-check"></i> To provide unique
                            qualities in our service
                        </li>
                        <li>
                            <i class="bx bx-check"></i> To promote small scale
                            business transport
                        </li>
                        <li>
                            <i class="bx bx-check"></i> High class
                            transportation at low price
                        </li>
                        <li>
                            <i class="bx bx-check"></i> To spread outlet to
                            every place
                        </li>
                        <li>
                            <i class="bx bx-check"></i> To satisfy all logistic
                            requirments
                        </li>
                        <li>
                            <i class="bx bx-check"></i> To make our world small
                            enough to reach around
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Info -->

<!-- Digital Area -->
<div class="digital-area ptb-100">
    <div class="container">
        <div class="digital-top-contant">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-image">
                        <img src="assets/img/blog/hands-working-digital-device-network-graphic-overlay.jpg"
                            alt="image" />
                        <!-- <a href='https://www.freepik.com/photos/logo'>Logo photo created by rawpixel.com - www.freepik.com</a> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-text">
                        <h2>Trusted Digital Shipping</h2>
                        <p>
                            Expanding our online presence would not have been possible without MCF. Getting started with
                            MCF was very simple, and the pooled inventory is perfect for our business. MCF is the
                            affordable, preferred fulfillment option that helped our ecommerce business take off.”
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="digital-card-contant">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class="bx bx-cart-alt"></i>
                            <h3><span>1998</span> - Company Started</h3>
                            <p>
                                Bulk cargo is a type of special cargo that is delivered and handled in large quantities.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class="bx bx-map-alt"></i>
                            <h3><span>2008</span> - Office worldwide</h3>
                            <p>
                                Bulk cargo is a type of special cargo that is delivered and handled in large quantities.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class="bx bxs-truck"></i>
                            <h3><span>2004</span> - Vehicles Adding</h3>
                            <p>
                                Bulk cargo is a type of special cargo that is delivered and handled in large quantities.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class="bx bx-award"></i>
                            <h3><span>2012</span> - Award Of The Year Won</h3>
                            <p>
                                Bulk cargo is a type of special cargo that is delivered and handled in large quantities.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Digital Area -->

<!-- partner Slider Area -->
<app-partner></app-partner>
<!-- End partner Slider Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="newsletter-form">
                        <form>
                            <input type="email" class="form-control" placeholder="Email Address" required
                                autocomplete="off" />
                            <button type="submit" class="btn btn-primary">
                                Subscribe
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->