<!-- Hero Slider -->
<div class="hero-slider owl-carousel owl-theme">
    <div class="hero-slider-item item-bg{{i + 1}}" *ngFor="let slide of slides; let i = index;">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <!-- <span>Since 1992</span> -->
                        <h1>{{slide.title}}</h1>
                        <p>{{slide.description}}</p>
                        <a [routerLink]="slide.links[0].url" class="default-btn-one mr-3">{{slide.links[0].text}}</a>
                        <a [routerLink]="slide.links[1].url" class="default-btn-two">{{slide.links[1].text}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Slider -->

<!-- Contact Area -->
<div class="container">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bxs-phone"></i>
                        <h4>Contact Number</h4>
                        <p><a href="tel:+97441429491">+974-41429491</a></p>
                        <p><a href="tel:+97455882735">+974-55882735</a></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bxs-location-plus"></i>
                        <h4>Our Location</h4>
                        <p>Building #43, Al Kazim Tower,</p>
                        <p>2nd Floor, Office #17</p>
                        <p>P.O.Box:9044, Doha - Qatar</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bx-show"></i>
                        <h4>Opening Hours</h4>
                        <p>9:00 AM - 6:00 PM</p>
                        <p>Saturday - Thursday</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class="bx bxs-envelope"></i>
                        <h4>Our Email</h4>
                        <p>
                            <a href="mailto:ibrahim@exlinefreights.com">ibrahim@exlinefreights.com</a>
                        </p>
                        <p>
                            <a href="mailto:sheena@exlinefrieghts.com">sheena@exlinefrieghts.com</a>
                        </p>
                        <p>
                            <a href="mailto:sales@exlinefrieghts.com">sales@exlinefrieghts.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->

<!-- About Area -->
<div class="about-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="about-contant">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>Safe and Faster Logistic Service Near You</h2>
                    </div>
                    <div class="about-text">
                        <p>
                            Our expertise and tech-enabled solutions are
                            carefully customised to suit client needs and ease
                            imports and exports. Our seasoned professionals
                            handle the inherent complexity of “international
                            order-delivery process” so that your cargo moves
                            across borders safely and on time.
                        </p>
                        <p>
                            Suite of services including tracking inland
                            transportation, preparation of shipping,
                            warehousing, negotiation and consolidation of
                            freight, insurance and collection services, we
                            deliver experience of international standards, not
                            just international parcels.
                        </p>
                        <a routerLink="/about" class="default-btn-one btn-bs">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" alt="about-image" />
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="about-tabs">
                    <div class="tab-contant">
                        <h2 class="title">Our Core Value</h2>
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-link active" id="nav-vision-tab" data-toggle="tab" href="#nav-vision"
                                    role="tab" aria-controls="nav-vision" aria-selected="true">Vision</a>
                                <a class="nav-link nav-link-two" id="nav-mission-tab" data-toggle="tab"
                                    href="#nav-mission" role="tab" aria-controls="nav-mission"
                                    aria-selected="false">Mission</a>
                                <a class="nav-link nav-link-two" id="nav-value-tab" data-toggle="tab" href="#nav-value"
                                    role="tab" aria-controls="nav-value" aria-selected="false">Value</a>
                            </div>
                        </nav>

                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-vision" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li>
                                            <i class="bx bx-check"></i> To
                                            provide services for more than
                                            thousands individual companies
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> To make
                                            the ease of accessing our servicses
                                            by maintaining franchises globally
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> To scale
                                            up the opertunities for small scale
                                            business
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> To give
                                            all kinds of transportational
                                            services at reasonable cost
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-mission" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li>
                                            <i class="bx bx-check"></i>
                                            Providing the fastest transportation
                                            service without any compramisation
                                            in safety
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> Making
                                            all kinds of transport available to
                                            small and large businesses
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> Breaking
                                            the barrier between customer and
                                            sevice provider(us)at any cost in
                                            terms of communication
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> Building
                                            infrastructure that provide all
                                            possiblities to cut cost in every
                                            medium of transport
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-value" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li>
                                            <i class="bx bx-check"></i>
                                            Efficiency is the key ingredient of
                                            our logistics, all kinds of logistic
                                            requirment can be done by few
                                            clicks.
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> We, your
                                            ELFS are indeed flexible to your
                                            requirments.
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> We are
                                            infinitely responsive to our
                                            customers, every query is repayed
                                            manually
                                        </li>
                                        <li>
                                            <i class="bx bx-check"></i> You can
                                            demand timeliness and punctuality
                                            and we never let loose on that.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safe and Faster Logistic Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bxs-ship"></i>
                    <h3>Ocean Cargo</h3>
                    <p>
                        Huge amount of shipments can be done in single sea
                        shipment
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bx-store"></i>
                    <h3>Cargo Storage</h3>
                    <p>
                        We provide cargo storage services to store your
                        shipments
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bxs-truck"></i>
                    <h3>Courier Delivery</h3>
                    <p>
                        Fast and secure delivery regardless the amount of goods
                    </p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class="bx bx-transfer"></i>
                    <h3>B2B Exchange</h3>
                    <p>Seemless business to business transportation</p>
                    <a routerLink="/services-details" class="default-btn-two">Read More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Choose Area -->
<div class="choose-area ptb-100">
    <div class="shape-one">
        <img src="assets/img/shape/shape5.png" alt="Shape" />
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class="bx bx-world"></i>
                        <h3>Worldwide Service</h3>
                        <p>
                            our hands of transportation spreaded across the
                            world
                        </p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class="bx bxs-paper-plane"></i>
                        <h3>Tracking Service</h3>
                        <p>we provide our best tracking system</p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class="bx bxs-truck"></i>
                        <h3>Fast and Reliable</h3>
                        <p>
                            we provide the fastest cargo transport service in
                            which you can rely on.
                        </p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class="bx bx-support"></i>
                        <h3>24/7 Support</h3>
                        <p>
                            our customer care service will be full responsive
                            through 24/7
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="choose-text">
                    <div class="section-title">
                        <span>Why Choose Us</span>
                        <h2>OUR STRONG FOUNDATION</h2>
                    </div>
                    <p>
                        Express Line Freight Services, (ELFS), a Qatar-based
                        freight forwarding company, established in year 2020,
                        founded by one of the pillars in the freight and
                        shipping industry of Qatar - MR. MOHAMED IBRAHIM SIKKANDAR BATCHA,
                    </p>
                    <p>
                        our company is managed by top professionals with tons of
                        experience, ELFS striving become one of the top freight
                        forwarding company in the country, providing the most
                        cost effective and efficient services to fit on every
                        client’s needs.
                    </p>
                    <a routerLink="/contact" class="default-btn-one">Contact Us</a>
                    <div class="shape-image">
                        <img src="assets/img/shape/shape2.png" alt="icon" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Choose Area -->

<!-- Speciality Slider Area -->
<div class="speciality-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Speciality</span>
            <h2>Safe and Faster Logistic Services</h2>
        </div>

        <div class="speciality-slider owl-carousel owl-theme">
            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/office-shifting.jpg"
                        alt="Office Shifting" /></a>
                <div class="speciality-text">
                    <h3>
                        <a routerLink="/services-details">Office Relocation</a>
                    </h3>
                    <p>Make your office relocation fun with our top office shifting companies and Office Moving Tips.
                        Relocate your office with all furniture, IT Equipment and other office goods anywhere across
                        your country at best charges. Compare pricing from our professional and reliable office
                        relocation service
                        providers, trusted by many.
                    </p>
                </div>
            </div>

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/house-moving.jpg"
                        alt="House Moving" /></a>
                <div class="speciality-text">
                    <h3><a routerLink="/services-details">House Moving</a></h3>
                    <p>
                        Tell us about your planned relocation. An experienced consultant will help asses all your needs.
                        You will receive a tailored service recommendation. We manage your move from end to end to
                        ensure everything runs smoothly. Our dedicated move teams are here to help and support you every
                        step of the way.
                    </p>
                </div>
            </div>
            <!-- House Moving - <a href='https://www.freepik.com/photos/people'>People photo created by yanalya - www.freepik.com</a> -->

            <div class="speciality-card">
                <a routerLink="/services-details"><img src="assets/img/speciality/courier-worldwide.jpg"
                        alt="Courier Worldwide" /></a>
                <div class="speciality-text">
                    <h3>
                        <a routerLink="/services-details">Courier Worldwide</a>
                    </h3>
                    <p>
                        Express Line Freights Service W.L.L., offers rail freight services throughout the Worldwide and
                        work directly with major Worldwide rail roads and owner’s operators. By moving freight
                        throughout Worldwide with an inter-mode carrier (rail and truck) we are able
                        to cuts costs, while increasing security and reducing any potential losses.
                    </p>
                </div>
            </div>
            <!-- Courier World wide - <a href='https://www.freepik.com/photos/delivery'>Delivery photo created by freepik - www.freepik.com</a> -->
        </div>
    </div>
</div>
<!-- End Speciality Slider Area -->

<!-- Testimonials Area -->
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="shape-two">
            <img src="assets/img/shape/shape6.png" class="shape-1" alt="shape" />
            <img src="assets/img/shape/shape3.png" alt="shape" />
        </div>

        <div class="section-title">
            <span>Feedback</span>
            <h2>Whats Our Clients Said About Us</h2>
        </div>

        <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client-default.png" alt="image" />
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
                <div class="feedback-text">
                    <p>
                        I am highly impressed with the professionalism and passion of people in this business. I am
                        also equally impressed with the "5S" principles followed in material storage. I am proud of you
                        all. Keep it up.
                    </p>
                </div>
            </div>

            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client-default.png" alt="Client" />
                    <h3>B2B Logistics</h3>
                    <span>Sales Managager</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
                <div class="feedback-text">
                    <p>
                        Very well organized operations, modern warehouses are very neat & clean. My heartiest
                        congratulation to OM Team.
                    </p>
                </div>
            </div>

            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client-default.png" alt="image" />
                    <h3>Benjamin Liam</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
                <div class="feedback-text">
                    <p>
                        Very nice process and monitoring. Tracking of goods movement & OPS is commendable.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Testimonials Area -->

<!-- Parter Area -->
<app-partner></app-partner>
<!-- End Partner Area -->

<!-- Transportation Area -->
<div class="transportation-area">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-6 col-md-12">
                <div class="transportation-form ptb-100">
                    <div class="section-title">
                        <span>Transportation</span>
                        <h2>Take Your Goods Anywhere</h2>
                    </div>

                    <div class="transportation-shape">
                        <img src="assets/img/shape/shape3.png" alt="icon" />
                    </div>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Height (CM):" />
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Weight (KG):" />
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Width (CM):" />
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Type of commodity:" />
                        </div>

                        <div class="form-group mb-30">
                            <input type="text" class="form-control" placeholder="Distance (KM):" />
                        </div>

                        <div class="form-btn">
                            <button type="submit" class="default-btn-two mr-4">
                                Cost Calculate
                            </button>
                            <button type="submit" class="default-btn-two">
                                Get A Full Quote
                            </button>
                        </div>

                        <h3>Get a brochure / 10% Discount on first order</h3>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="popup-video video-bg">
                    <div class="d-table d-none">
                        <div class="d-table-cell">
                            <div class="video-btn">
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube">
                                    <i class="bx bx-play whiteText"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- popup video bg - <a href='https://www.freepik.com/photos/business'>Business photo created by pressfoto - www.freepik.com</a> -->
            </div>
        </div>
    </div>
</div>
<!-- End Transportation Area -->