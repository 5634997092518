<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo">
                        <img src="assets/img/exline-logo-header.png" alt="logo" />
                    </div>
                    <p>Express Line Freight Services, (ELFS), a Qatar-based freight forwarding company, established
                        in year 2020, founded by one of the pillars in the freight and shipping industry of Qatar -
                        MR. MOHAMED IBRAHIM SIKKANDAR BATCHA
                    </p>
                    <ul class="footer-socials">
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Company</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Cost calculator</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3>Support</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/faq">FAQ’s</a></li>
                        <li>
                            <a routerLink="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a routerLink="/terms-conditions">Terms & Conditions</a>
                        </li>
                        <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="info-list">
                        <li>
                            <i class="bx bxs-location-plus"></i>
                            Building #43, Al Kazim Tower, 2nd Floor, Office #17
                            P.O.Box:9044, Doha - Qatar
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:sales@exlinefreights.com">ibrahim@exlinefreights.com</a>
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:info@exlinefreights.com">sheena@exlinefreights.com</a>
                        </li>
                        <li>
                            <i class="bx bxs-phone"></i>
                            <a href="tel:+97441429491">+974-41429491</a><br />
                            <a href="tel:+97450636034">+974-50636034</a><br />
                            <a href="tel:+97455882735">+974-55882735</a><br />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<div class="footer-bottom">
    <div class="container">
        <p>
            Copyright @2021 Express Line Freight Services. All Rights Reserved
            <a href="https://exlinefreights.com/" target="_blank">exlinefreights.com</a>
        </p>
    </div>
</div>
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class="bx bx-chevrons-up"></i>
</div>
<!-- End Go Top -->