import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  slides = [
    {
      title: "Freight Forwarding, Sea & Air",
      description: `We are providing ease and smooth service from beginning to end`,
      links: [{
        text: "Contact Us",
        url:"/contact"
      },{
        text: "Get A Quote",
        url:"/"
      },
      ]
    },
    {
      title: "Shipping across countries",
      description: `By Sea, Air & Land, or using all combination of three`,
      links: [{
        text: "Contact Us",
        url:"/contact"
      },{
        text: "Get A Quote",
        url:"/"
      },
      ]
    },
    {
      title: "Custom Clearance & Transport",
      description: `As experienced customs clearance agents, our highly trained and experienced team simplifies the process of import and export.`,
      links: [{
        text: "Contact Us",
        url:"/contact"
      },{
        text: "Get A Quote",
        url:"/"
      },
      ]
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
