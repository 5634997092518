<!-- Page banner Area -->
<div class="page-banner contact-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Contact With Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Conatct Info -->
<div class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class="bx bxs-phone"></i>
                    <h4>Contact Numbers</h4>
                    <p><a href="tel:+97441429491">+974-41429491</a></p>
                    <p><a href="tel:+97455882735">+974-55882735</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class="bx bxs-location-plus"></i>
                    <h4>Our Location</h4>
                    <p>{{address}}</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-info">
                    <i class="bx bxs-envelope"></i>
                    <h4>Email</h4>
                    <p *ngFor="let email of emails">
                        <a href="mailto:{{email}}">{{email}}</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class=" col-lg-10 col-md-8">
                <div class="responsive-map">
                    <iframe class="responsive-iframe"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.877185829884!2d51.5396546153816!3d25.27471653475269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c542870a812d%3A0xee1b00651b47e19c!2sExpress%20Line%20Freight%20Services%20W.L.L!5e0!3m2!1sen!2sin!4v1635090788388!5m2!1sen!2sin"
                        allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Conatct Info -->

<!-- Contact Area -->
<div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" id="name" required
                                placeholder="Your name" />
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" id="email" required
                                placeholder="Your email address" />
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                placeholder="Your Subject" />
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" id="phone_number" required
                                placeholder="Your phone number" />
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" id="message" class="form-control" cols="30" rows="6" required
                                placeholder="Write your message..."></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one">
                            Send Message
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Contact Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off" />
                        <button type="submit" class="btn btn-primary">
                            Subscribe
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->