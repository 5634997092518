<!-- Partner Area -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Partner</span>
            <h2>Companies Trusted us and Getting Result</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item" *ngFor="let partner of partners">
                <a href="#" target="_blank"><img src="assets/img/partner/{{partner.img}}" height="60"
                        alt="{{partner.name}}" /></a>
            </div>
        </div>
    </div>
</div>