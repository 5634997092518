<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Special Testimonials</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Testimonials</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Testimonials Slider Area -->
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Clients Review</span>
            <h2>Whats Our Clients Said About Us</h2>
        </div>

        <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-card">
                <div class="client-img">
                    <img src="assets/img/clients/client6.jpg" alt="image" />
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
                <div class="feedback-text">
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                </div>
            </div>

            <div class="testimonials-card">
                <div class="client-img">
                    <img
                        src="assets/img/clients/client-default.png"
                        alt="image"
                    />
                    <h3>William leo</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
                <div class="feedback-text">
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                </div>
            </div>

            <div class="testimonials-card">
                <div class="client-img">
                    <img
                        src="assets/img/clients/client-default.png"
                        alt="image"
                    />
                    <h3>Benjamin Liam</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                    <i class="bx bxs-star"></i>
                </div>
                <div class="feedback-text">
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Testimonials Slider Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Enter your email"
                            name="EMAIL"
                            required=""
                            autocomplete="off"
                        />
                        <button type="submit" class="btn btn-primary">
                            Subscribe
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
