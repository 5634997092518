<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Privacy Policy Area -->
<div class="privacy-policy ptb-100">
    <div class="container">
        <div class="privacy-policy-text">
            <p>
                This Privacy Policy explains the policy regarding the
                collection, use, disclosure and transfer of your information by
                www.exlinefreights.com for all the services of ELFS
                ("Services"). This policy may change as we update, improve and
                expand the Services, so please check it periodically. By
                accessing the Site of ELFS or otherwise using the Services, you
                consent to collection, storage, and use of the personal
                information you provide (including any changes thereto as
                provided by you) for any of the Services offered by us.
            </p>
            <h2>Sources of Information</h2>
            <p>
                We collect and process the information from you from the
                following sources to provide better services to all of our
                users.
            </p>
            <h2>Registration data</h2>
            <p>
                This includes basic contact information such as name, gender,
                age, address, pin code, contact number, occupation, interests
                and email address etc. provided at the time of registration to
                use the Site and seeking or obtaining Services from us.
            </p>
            <h2>Paid service data</h2>
            <p>
                When you make any payment for the Services, ELFS or its payment
                gateway provider may collect your purchase, address or billing
                information, including your credit card number and expiration
                date etc.
            </p>
            <h2>Voluntary information</h2>
            <p>
                ELFS may collect additional information at other times,
                including but not limited to, when you provide feedback, change
                your Error message need to be informative and display clearly
                content or email preferences, respond to a survey, or
                communicate with ELFS.
            </p>
            <h2>Cookies</h2>
            <p>
                ELFS use data collection devices such as "cookies" on certain
                pages of its Site. Cookies are small files which are stored on
                your hard drive that assist us in providing customized services.
                We also offer certain features that are only available through
                the use of a "cookie". Cookies help us to provide information,
                which is targeted to your interests. Cookies may be used whether
                you register with us or not.
            </p>
            <h2>Links to other websites</h2>
            <p>
                ELFS Site may, from time to time, contain links to and from
                other websites or applications to give you a better service.
                Such websites or applications are governed by their respective
                privacy policies, which are beyond our control. Once you leave
                our servers, use of any information you provide is governed by
                the privacy policy of the operator of the application, you are
                visiting and we do not accept any responsibility or liability
                for these policies.
            </p>
            <h2>Use of your information</h2>
            <p>
                The Information as supplied by you enables Error message need to
                be informative and display us to improve Error message need to
                be informative and display clearly the Services and provide you
                the most user-friendly experience. All required information is
                service dependent and we may use the above said user Information
                to, maintain, protect, and improve the Services and for
                developing new services. We may also use your email address or
                other personally identifiable information to send commercial or
                marketing messages without your consent [with an option to
                subscribe / unsubscribe (where feasible)]. We may, however, use
                your email address without further consent for non-marketing or
                administrative purposes (such as notifying you of major changes,
                for customer service purposes, billing, etc.). Without
                limitation to the foregoing, your information may be used for
                the following purposes: To develop, enhance, market, sell or
                provide our services, or those of companies with which we have a
                commercial relationship To provide tips or guidance on how to
                use our website, inform you of new features on our website, or
                provide other information that may be of interest to you To
                evaluate credit standing and to match credit bureau or credit
                reporting agency information; to investigate, detect and protect
                ourselves and other third parties against error, negligence,
                breach of contract, fraud, theft and other illegal activities To
                effect a corporate transaction, in connection with the sale,
                merger, spin-off or other corporate reorganization of our
                corporation, where the information is provided to the new
                controlling entity in regular course of business To audit
                compliance with our policies and contractual obligations
            </p>
            <h2>Disclosure of your information</h2>
            <p>
                ELFS shares your information with any third party without
                obtaining your prior consent in the following limited
                circumstances: When it is requested or required by law or by any
                court or governmental agency or authority to disclose, for the
                purpose of verification of identity, or for the prevention,
                detection, investigation including cyber incidents, or for
                prosecution and punishment of offences. These disclosures are
                made in good faith and belief that such disclosure is reasonably
                necessary for enforcing these terms or for complying with the
                applicable laws and regulations ELFS proposes to share such
                information within its group companies and employees of such
                group companies for the purpose of processing personal
                information on its behalf. We also ensure that these recipients
                of such information agree to process such information based on
                our instructions and in compliance with this Privacy Policy and
                any other appropriate confidentiality and security measures ELFS
                may disclose information to third parties to detect, prevent or
                otherwise address fraud, security or technical issues or protect
                the rights, property or safety of us, our users or others or in
                order to enforce or apply our terms of use
            </p>
            <h2>Information Security</h2>
            <p>
                ELFS take appropriate security measures to protect against
                unauthorized access to or unauthorized alteration, disclosure or
                destruction of data. These include internal reviews of our data
                collection, storage and processing practices and security
                measures, including appropriate encryption and physical security
                measures to guard against unauthorized access to systems where
                we store personal data. However, as effective as our security
                measures are, no security system is impenetrable. We cannot
                guarantee the security of our database, nor can we guarantee
                that information you supply will not be intercepted while being
                transmitted to us over the Internet.
            </p>
            <h2>Others</h2>
            <p>
                We always seek to improve the Services we offer which are
                dynamic and innovative in nature. We often introduce new
                features, some of which may result in the collection of new
                information. If we start collecting substantially new type of
                information and materially change how we handle your data, we
                modify this Privacy Policy accordingly.
            </p>
        </div>
    </div>
</div>
<!-- End Privacy Policy Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Enter your email"
                            name="EMAIL"
                            required=""
                            autocomplete="off"
                        />
                        <button type="submit" class="btn btn-primary">
                            Subscribe
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
