<!-- Header Area -->
<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li>
                            <a href="mailto:sales@exlinefreights.com"><i class="bx bxs-envelope"></i>
                                ibrahim@exlinefreights.com</a>
                        </li>
                        <li>
                            <a href="tel:+97450636034"><i class="bx bxs-phone-call"></i>
                                +974-50636034</a>
                        </li>
                        <li>
                            <a href="tel:+97455882735"><i class="bx bxs-phone-call"></i>
                            +974-55882735</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <li class="mr-20">
                            <a routerLink="/contact">Contact</a>
                        </li>
                        <li class="mr-20"><a routerLink="/">Career</a></li>
                        <li class="mr-20">
                            <a routerLink="/">News & Media</a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="navbar-area">
        <div class="exlinefs-responsive-nav">
            <div class="container">
                <div class="exlinefs-responsive-menu">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/exline-logo-header.png" alt="logo" /></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="exlinefs-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/exline-logo-header.png"
                            alt="logo" /></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                    }">Home</a>
                                <!-- <a href="javascript:void(0)" class="nav-link"
                                    >Home
                                    <i class="bx bx-chevron-down"></i>
                                </a> -->

                                <!-- <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Demo - 1</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/home-two"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Demo - 2</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/home-three"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Demo - 3</a
                                        >
                                    </li>
                                </ul> -->
                            </li>

                            <li class="nav-item">
                                <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">About Us</a>
                            </li>

                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Pages <i class="bx bx-chevron-down"></i
                                ></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/team"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Team</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/testimonials"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Testimonials</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/gallery"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Gallery</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/faq"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >FAQ</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            href="javascript:void(0)"
                                            class="nav-link"
                                            >User
                                            <i class="bx bx-chevron-right"></i
                                        ></a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/sign-in"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Sign In</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    routerLink="/sign-up"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Sign Up</a
                                                >
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/privacy-policy"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Privacy Policy</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/terms-conditions"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Terms & Conditions</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/coming-soon"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Coming Soon</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/error"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >404 Error Page</a
                                        >
                                    </li>
                                </ul>
                            </li> -->

                            <li class="nav-item">
                                <a routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{
                                        exact: true
                                    }">Services</a>
                            </li>

                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Services <i class="bx bx-chevron-down"></i
                                ></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/services"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Services</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/services-details"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Single Services</a
                                        >
                                    </li>
                                </ul>
                            </li> -->

<!-- 
                            <li class="nav-item">
                                <a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{
                                        exact: true
                                    }">Blog</a>
                            </li> -->

                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Blog <i class="bx bx-chevron-down"></i
                                ></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/blog"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Blog</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/blog-details"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Single Blog</a
                                        >
                                    </li>
                                </ul>
                            </li> -->

                            <li class="nav-item">
                                <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }">Contact</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- End Header Area -->